"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleCategory = void 0;
var VehicleCategory;
(function (VehicleCategory) {
    VehicleCategory["CAR"] = "Voiture";
    VehicleCategory["ELECTRIC_CAR"] = "Voiture \u00E9lectrique";
    VehicleCategory["CAMPING_CAR"] = "Camping car";
    VehicleCategory["MOTORCYCLE"] = "2 roues motoris\u00E9es";
    VehicleCategory["TRUCK"] = "Camion";
    VehicleCategory["BUS"] = "Autocar";
    VehicleCategory["HEAVY_CAR"] = "V\u00E9hicule lourd";
    // Type spécifique de véhicules utile à la MGP
    VehicleCategory["COLLECTION"] = "V\u00E9hicule de collection";
    VehicleCategory["OLD"] = "V\u00E9hicule de plus de 30 ans";
    VehicleCategory["SPECIALIZED"] = "V\u00E9hicule sp\u00E9cialis\u00E9";
})(VehicleCategory = exports.VehicleCategory || (exports.VehicleCategory = {}));
