import React from 'react';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import TextField from 'material-ui/TextField';
import Checkbox from 'material-ui/Checkbox';
import CircularProgress from 'material-ui/CircularProgress';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import BoButton from 'facade/BoButton';
import { fetchOrganizations } from 'api/organizations';
import { CityOrganizationDTO } from 'api/organizations/types';
import {
  AgentUpsertDTO,
  AgentProfileDTO,
  MeanOfAuthenticationTypeDTO,
} from '@cvfm-front/tefps-types';
import {
  createAgentWithAuthenticationMeans,
  fetchLowestShortId,
} from 'api/accounts';
import { ApiError } from 'api/ApiError';
import { processPasswordValidation } from '@cvfm-front/commons-utils';
import PasswordField from 'commons/Fields/PasswordField';

import {
  generateAgentId,
  validateAgentIdField,
  validateAgentLoginField,
  hasGlobalAdminSuffix,
} from '../helper';

const { _t, _tg } = window.loadTranslations(__filename);

type CreateAgentErrors =
  | 'lastName'
  | 'firstName'
  | 'organizationId'
  | 'agentId'
  | 'login'
  | 'password'
  | 'profileId'
  | 'shortId'
  | 'email';
type CreateAgentError = { [k in CreateAgentErrors]?: string };

type Props = {
  profiles: Array<AgentProfileDTO>;
  onCreate: (message: string) => Promise<void>;
};

type State = {
  isOpen: boolean;
  loading: boolean;
  agent: AgentUpsertDTO;
  meanType: MeanOfAuthenticationTypeDTO;
  organizations: Array<CityOrganizationDTO>;
  errors: CreateAgentError;
};

const validateTextFieldAndSetError = (
  data: AgentUpsertDTO,
  field: keyof AgentUpsertDTO
) => {
  const fieldValue = data[field];
  if (typeof fieldValue === 'string') {
    return fieldValue && fieldValue.trim() !== '';
  }
  return false;
};

const initAgent = (): AgentUpsertDTO => ({
  firstName: '',
  lastName: '',
  profileId: '',
  organizationId: '',
  agentId: generateAgentId(),
  shortId: '',
  login: '',
  password: '',
  email: null,
  enabled: true,
  isLapi: false,
  assignment: null,
  picture: null,
  agentFamilyId: null,
});

class CreateAgent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
      loading: true,
      agent: initAgent(),
      meanType: 'PASSWORD',
      organizations: [],
      errors: {},
    };
  }

  onCheckShortIdBox = (
    event: React.MouseEvent<HTMLInputElement>,
    isInputChecked: boolean
  ): void => {
    if (isInputChecked) {
      const { agent } = this.state;
      void this.loadShortId(agent.organizationId);
    } else {
      this.clearShortId();
    }
  };

  loadOrganizations = async (): Promise<void> => {
    const organizations = await fetchOrganizations(false, 'all');
    this.setState({ organizations, loading: false });
  };

  loadShortId = async (organizationId: string): Promise<void> => {
    const shortId = await fetchLowestShortId(organizationId);
    const { agent } = this.state;

    this.setState({
      agent: {
        ...agent,
        shortId,
      },
      errors:
        !shortId || shortId === 'XXX'
          ? {
              shortId: _t('feedback.error.tooManyAgents'),
            }
          : {},
    });
  };

  handleOpen = (): void => {
    this.setState({
      isOpen: true,
      agent: initAgent(),
      errors: {},
    });
    void this.loadOrganizations();
  };

  handleClose = (): void => this.setState({ isOpen: false });

  handleChangeMeanType = (
    event: unknown,
    index: number,
    value: MeanOfAuthenticationTypeDTO
  ): void => this.setState({ meanType: value });

  handleChangeTextProp = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>): void => {
    const update: Record<string, unknown> = {};
    const { agent } = this.state;
    update[target.dataset.field as string] = target.value;
    // Update the login when agentId is update if they're equals
    if (target.dataset.field === 'agentId' && agent.login === agent.agentId) {
      update.login = target.value;
    }
    this.setState({ agent: { ...agent, ...update } });
  };

  handleChangeOrganization = (
    e: React.ChangeEvent<HTMLInputElement>,
    i: number,
    organizationId: string
  ): void => {
    const { agent } = this.state;
    void this.loadShortId(organizationId);
    this.setState({ agent: { ...agent, organizationId } });
  };

  handleChangeProfile = (
    e: React.ChangeEvent<HTMLInputElement>,
    i: number,
    profileId: string
  ): void => {
    const { agent } = this.state;
    this.setState({ agent: { ...agent, profileId } });
  };

  clearShortId = (): void => {
    const { agent } = this.state;
    this.setState({ agent: { ...agent, shortId: null } });
  };

  validateAndSubmit = async (): Promise<void> => {
    const { onCreate } = this.props;
    const { agent, meanType } = this.state;
    const errors: CreateAgentError = {};

    if (!validateTextFieldAndSetError(agent, 'lastName')) {
      errors.lastName = _t('feedback.error.lastName');
    }
    if (!validateTextFieldAndSetError(agent, 'firstName')) {
      errors.firstName = _t('feedback.error.firstName');
    }
    if (!validateTextFieldAndSetError(agent, 'organizationId')) {
      errors.organizationId = _t('feedback.error.organisationId');
    }
    if (!validateAgentIdField(agent.agentId)) {
      errors.agentId = _t('feedback.error.agentId');
    }
    if (!validateTextFieldAndSetError(agent, 'login')) {
      errors.login = _t('feedback.error.login');
    }
    if (
      meanType === 'PASSWORD' &&
      !processPasswordValidation({ password: agent.password || '' }).status
    ) {
      errors.password = _t('feedback.error.password');
    }
    if (
      meanType !== 'PASSWORD' &&
      !validateTextFieldAndSetError(agent, 'password')
    ) {
      errors.password = _t('feedback.error.password');
    }
    if (!validateTextFieldAndSetError(agent, 'profileId')) {
      errors.profileId = _t('feedback.error.profileId');
    }
    if (!validateAgentLoginField(agent.login || '')) {
      errors.login = _t('feedback.error.agentNumber');
    }
    if (hasGlobalAdminSuffix(agent.login || '')) {
      errors.login = _t('feedback.error.isGlobalAdmin', {
        suffix: (agent.login || '').split('@')[1], // if agent.login is null, we won't enter this if so it won't throw an exception
      });
    }

    const shortId = agent.shortId ? Number.parseInt(agent.shortId, 10) : null;
    if (shortId && !errors.shortId && (shortId > 999 || shortId <= 0)) {
      errors.shortId = _t('feedback.error.tooManyAgents');
    }

    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      let message = '';
      try {
        await createAgentWithAuthenticationMeans(
          agent,
          meanType,
          agent.login || '',
          agent.password || '',
          false
        );
        message = _t('feedback.success.agentCreated');
        this.handleClose();
      } catch (e) {
        const err = e as ApiError;
        if (e?.json?.error === 'InvalidEmailException') {
          message = `${_t('feedback.success.agentCreated')}, ${_tg(
            'errors.InvalidEmailException'
          )}`;
          this.handleClose();
        } else {
          ({ message } = err);
        }
      }
      await onCreate(message);
    }

    this.setState({ errors, loading: false });
  };

  render(): React.ReactNode {
    const { profiles } = this.props;
    const {
      isOpen,
      agent,
      meanType,
      loading,
      organizations,
      errors,
    } = this.state;

    return (
      <div>
        <BoButton
          label={_t('element.button.createAgent')}
          style={{ marginRight: 30 }}
          onClick={this.handleOpen}
        />
        <Dialog
          title={_t('element.dialog.title')}
          onRequestClose={this.handleClose}
          modal
          open={isOpen}
          autoScrollBodyContent
          actions={[
            <FlatButton
              label={_tg('action.cancel')}
              onClick={this.handleClose}
              disabled={loading}
            />,
            <FlatButton
              label={_tg('action.create')}
              onClick={this.validateAndSubmit}
              disabled={loading}
              primary
            />,
          ]}
        >
          {loading ? (
            <div
              style={{
                height: 150,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <TextField
                floatingLabelText={_tg('field.human.lastname_short')}
                errorText={errors.lastName}
                value={agent.lastName}
                data-field="lastName"
                onChange={this.handleChangeTextProp}
              />
              <TextField
                floatingLabelText={_tg('field.human.firstname')}
                errorText={errors.firstName}
                value={agent.firstName}
                data-field="firstName"
                onChange={this.handleChangeTextProp}
              />
              <TextField
                floatingLabelText={_t('element.dialog.form.email')}
                errorText={errors.email}
                value={agent.email || undefined}
                data-field="email"
                onChange={this.handleChangeTextProp}
              />
              <SelectField
                floatingLabelText={_tg('field.organisation.organisation')}
                value={agent.organizationId}
                errorText={errors.organizationId}
                onChange={this.handleChangeOrganization}
              >
                {organizations.map(org => (
                  <MenuItem
                    key={org.organizationId}
                    value={org.organizationId}
                    primaryText={org.name}
                  />
                ))}
              </SelectField>
              <SelectField
                floatingLabelText={_t('element.dialog.form.profileId')}
                value={agent.profileId}
                errorText={errors.profileId}
                onChange={this.handleChangeProfile}
              >
                {profiles.map(profile => (
                  <MenuItem
                    key={profile.profileId}
                    value={profile.profileId}
                    primaryText={profile.name}
                  />
                ))}
              </SelectField>
              <TextField
                floatingLabelText={_t('element.dialog.form.agentId')}
                style={{ width: 'auto', flexGrow: 1, maxWidth: '80%' }}
                errorText={errors.agentId}
                value={agent.agentId}
                data-field="agentId"
                onChange={this.handleChangeTextProp}
              />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  maxWidth: '80%',
                }}
              >
                <TextField
                  floatingLabelText={_t('element.dialog.form.shortId')}
                  style={{ flexGrow: 1 }}
                  errorText={errors.shortId}
                  value={agent.shortId || undefined}
                  disabled
                />
                <Checkbox
                  style={{ width: 'auto' }}
                  onCheck={this.onCheckShortIdBox}
                  checked={agent.shortId != null}
                  title={_t('element.dialog.form.shortIdInfo')}
                />
              </div>
              <SelectField
                floatingLabelText={_t('element.dialog.form.authType')}
                value={meanType}
                onChange={this.handleChangeMeanType}
              >
                <MenuItem
                  value="PASSWORD"
                  primaryText={_t('element.dialog.form.passwordType')}
                />
                <MenuItem
                  value="PIN"
                  primaryText={_t('element.dialog.form.pinType')}
                />
              </SelectField>
              <TextField
                floatingLabelText={
                  meanType === 'PASSWORD'
                    ? _t('element.dialog.form.id')
                    : _t('element.dialog.form.agentNumber')
                }
                errorText={errors.login}
                value={agent.login}
                data-field="login"
                onChange={this.handleChangeTextProp}
                style={{ width: 300 }}
              />

              {meanType === 'PASSWORD' ? (
                <PasswordField
                  value={agent.password || ''}
                  label={_t('element.dialog.form.password')}
                  errorText={errors.password}
                  onChange={this.handleChangeTextProp}
                />
              ) : (
                <TextField
                  floatingLabelText={_t('element.dialog.form.pin')}
                  errorText={errors.password}
                  value={agent.password}
                  data-field="password"
                  style={{ width: 300 }}
                  onChange={this.handleChangeTextProp}
                />
              )}
            </div>
          )}
        </Dialog>
      </div>
    );
  }
}

export default CreateAgent;
