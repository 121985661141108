import { AgentAccountFullDTO, AgentUpsertDTO } from '@cvfm-front/tefps-types';

export const buildUpsertFromAgentAccountFullDTO = (
  agent: AgentAccountFullDTO
): AgentUpsertDTO => {
  return {
    firstName: agent.firstName,
    lastName: agent.lastName,
    profileId: agent.profileId,
    organizationId: agent.organizationId,
    agentId: agent.agentId,
    shortId: agent.shortId,
    email: agent.email,
    enabled: agent.enabled,
    isLapi: agent.isLapi,
    picture: null,

    // These 2 fields are useless for upsert, they're used in the creation modal
    login: '',
    password: '',
    assignment: agent.assignment,
    agentFamilyId: agent.agentFamilyId,
  };
};

// Should be the same as in AgentService.AGENT_ID_FORMAT
const AGENT_ID_FORMAT = /^[a-zA-Z0-9][a-zA-Z0-9_-]{0,9}$/;
export const validateAgentIdField = (agentId: string): boolean => {
  return !!AGENT_ID_FORMAT.exec(agentId);
};

// we exclude spaces inside the agent number
const AGENT_LOGIN_FORMAT = /^[^ ]*$/;
export const validateAgentLoginField = (agentNumber: string): boolean => {
  return !!AGENT_LOGIN_FORMAT.exec(agentNumber);
};

const GLOBAL_ADMIN_SUFFIXES = [
  '@polyconseil.fr',
  '@ier.fr',
  '@streeteo.com',
  '@group-indigo.com',
  'go-easier.com',
];
export const hasGlobalAdminSuffix = (username: string): boolean => {
  return GLOBAL_ADMIN_SUFFIXES.some(suffix => username.endsWith(suffix));
};

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
// The maximum is exclusive and the minimum is inclusive
function getRandomInt(min: number, max: number) {
  const lowerBound = Math.ceil(min);
  const higherBound = Math.floor(max);
  return Math.floor(Math.random() * (higherBound - lowerBound)) + lowerBound;
}

/**
 * Generate a 10 chars (alphaNumeric) agentId with low risk of collision
 */
const AGENT_ID_LENGTH = 10; // see: AGENT_ID_FORMAT
const ZEROS = new Array(AGENT_ID_LENGTH).fill('0').join('');

export const generateAgentId = (): string => {
  let result = Date.now().toString(36);
  const diff = AGENT_ID_LENGTH - result.length;
  if (diff) {
    const suffix = getRandomInt(0, 36 ** diff).toString(36);
    const valueWithPrependZero = (ZEROS + suffix).slice(-diff);
    result += valueWithPrependZero;
  }
  return result;
};
