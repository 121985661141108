"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.arrayToOption = void 0;
const arrayToOption = (arrayValueField, arrayLabelField) => {
    if (arrayLabelField && arrayLabelField.length === arrayValueField.length) {
        return arrayLabelField.map((item, index) => {
            const label = item;
            const value = arrayValueField[index];
            return {
                label,
                value,
            };
        });
    }
    return arrayValueField.map((item) => {
        const label = item.toString();
        const value = item;
        return {
            label,
            value,
        };
    });
};
exports.arrayToOption = arrayToOption;
